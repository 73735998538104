@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito&family=PT+Sans&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Nunito:wght@400;700&family=Playfair+Display:wght@600;700&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Nunito:wght@400;700&family=Playfair+Display:wght@600&family=Roboto+Condensed:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300&family=Nunito:wght@400;700&family=Roboto+Condensed:wght@300;400;700&display=swap");

$small: 630px;
$medium: 1200px;

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  @media screen and (max-width: $small) {
    width: 4px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--lightestgrey);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #97aac6;
  border-radius: 5px;
}

hr {
  border: 1px solid white;
  width: 50%;
  animation-name: page-fade-in;
  animation-duration: 2s;
}

.hr-rule {
  margin: 60px 0 30px 0;
}

.screen {
  height: 100dvh;
  width: 100dvw;
  display: flex;
}

.container-light {
  margin: 0;
  width: 100%;
  border: 20px solid white;
  box-sizing: border-box;
}

.container-dark {
  margin: 0;
  width: 100%;
  border: 20px solid black;
  box-sizing: border-box;
}

.view {
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

.entry-container {
  height: 100dvh;
  width: 100dvw;
  background: #495e89;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contents-container {
  @media screen and (max-width: $small) {
    height: 100dvh;
    width: 90dvw;
    background: transparent;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (min-width: $small) and (max-width: $medium) {
    height: 100dvh;
    width: 60dvw;
    background: transparent;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  @media screen and (min-width: $medium) {
    height: 100dvh;
    width: 30dvw;
    background: transparent;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.entry-text-header {
  color: white;
  font-size: 36px;
  padding: 42px 52px 82px 32px;
  text-align: left;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  @media screen and (max-width: $small) {
    font-size: 28px;
  }
}

.entry-text-description {
  color: white;
  font-size: 24px;
  padding: 82px 52px 52px 32px;
  font-weight: 300;
  text-align: left;
  font-family: "Lato", sans-serif;
  @media screen and (max-width: $small) {
    font-size: 20px;
  }
}

// Info Heading Section for info pages

.top-padding-large {
  padding-top: 210px;
  @media screen and (max-width: $small) {
    padding-top: 130px;
  }
}

.top-padding-medium {
  padding-top: 90px;
  @media screen and (max-width: $small) {
    padding-top: 60px;
  }
}

.top-padding-small {
  padding-top: 30px;
  @media screen and (max-width: $small) {
    padding-top: 20px;
  }
}

.bottom-padding-large {
  padding-bottom: 210px;
  @media screen and (max-width: $small) {
    padding-bottom: 130px;
  }
}

.bottom-padding-medium {
  padding-bottom: 90px;
  @media screen and (max-width: $small) {
    padding-bottom: 60px;
  }
}

.bottom-padding-small {
  padding-bottom: 30px;
  @media screen and (max-width: $small) {
    padding-bottom: 20px;
  }
}

.bottom-padding-immense {
  padding-bottom: 55vh;
  @media screen and (max-width: $small) {
    padding-bottom: 1300px;
  }
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.right-align {
  text-align: right;
}

.info-header {
  color: white;
  font-size: 42px;
  width: 60dvw;
  font-weight: 700;
  font-family: "Playfair Display", serif;
  opacity: 1;
  animation-name: page-fade-in;
  animation-duration: 2s;

  @media screen and (max-width: $small) {
    font-size: 18px;
  }
}

.info-page-text {
  color: white;
  font-size: 24px;
  width: 60dvw;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  opacity: 1;
  animation-name: page-fade-in;
  animation-duration: 2s;

  @media screen and (max-width: $small) {
    font-size: 18px;
  }
}

.image-holder {
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-images {
  width: 60%;
  opacity: 1;
  max-height: 300px;
  object-fit: cover;
  animation-name: page-fade-in;
  animation-duration: 2s;
  @media screen and (max-width: $small) {
    width: 50%;
  }
}

// end of section

.button-style {
  text-decoration: none;
  width: 350px;
  height: 50px;
  margin: 20px 55px 0px 55px;
  background-color: #97aac6;
  border-radius: 30px;
  border: solid 2px #97aac6;
  position: relative;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: $small) {
    width: 240px;
    height: 40px;
  }
}

.button-text-style {
  color: white;
  font-size: 24px;
  font-weight: 300;
  margin: 0px;
  font-family: "Playfair Display", serif;
  @media screen and (max-width: $small) {
    font-size: 20px;
  }
}

.button-style:hover {
  background: #6179ae;
  border: solid 2px #6179ae;
  cursor: pointer;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-container {
  position: absolute;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-container {
  height: 100dvh;
  width: 100dvw;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  overflow-x: hidden;
}

@keyframes content-page-animation {
  0% {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background: #495e89;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  99% {
    height: 100000px;
    width: 100000px;
    border-radius: 100%;
    background: #495e89;
    position: absolute;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes page-fade-out {
  0% {
    opacity: 1;
  }
}

.fade-container {
  opacity: 0;
  height: 100dvh;
  width: 100dvw;
  background: #495e89;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  animation-name: page-fade-out;
  animation-duration: 3s;
}

@keyframes page-fade-in {
  0% {
    opacity: 0;
  }
}

.bg-contents-container {
  width: 100dvw;
  background: #495e89;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  flex-direction: column;
  animation-name: page-fade-in;
  animation-duration: 0.3s;
}

.icon-contents-container {
  height: 300px;
  width: 300px;
  background: transparent;
  position: absolute;
  z-index: 200;
  display: flex;
  justify-content: center;
  flex-direction: column;
  animation-name: page-fade-in;
  animation-duration: 2s;
  :hover {
    cursor: pointer;
  }
}

.close-icon {
  @media screen and (max-width: $small) {
    height: 40px;
    width: 40px;
    position: fixed;
    top: 30px;
    left: 30px;
    z-index: 200;
    :hover {
      cursor: pointer;
    }
  }
  @media screen and (min-width: $small) {
    height: 50px;
    width: 50px;
    position: fixed;
    top: 70px;
    left: 70px;
    z-index: 200;
    :hover {
      cursor: pointer;
    }
  }
}

.fade-container-clicker {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: absolute;
  opacity: 0.6;
  left: 50%;
  top: 20%;
  margin-left: -50px;
  z-index: 30;
  opacity: 0;
  animation-name: clicker-fade-out;
  animation-duration: 7s;
}

@keyframes clicker-fade-out {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
}

.clicker {
  width: 100px;
  height: 100px;
  left: 50%;
  top: 20%;
  margin-left: -50px;
  position: absolute;
  z-index: 40;
}

.clicker-background {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: absolute;
  background-color: white;
  opacity: 0.6;
  left: 50%;
  top: 20%;
  margin-left: -50px;
  z-index: 30;
}

.clicker-background::before {
  position: absolute;
  content: "";
  border-top: transparent 15px solid;
  border-bottom: transparent 15px solid;
  border-left: #fff 25px solid;
  top: 20%;
  left: 50%;
  transform: translate(-40%, -50%);
}

.clicker-background::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: #fff solid 2px;
  border-radius: 100%;
  animation: ringPulse 0.6s infinite linear;
}

.dark-container {
  width: 80px;
  height: 80px;
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  right: 25px;
  top: 25px;
  z-index: 60;
  cursor: pointer;
}

.dark-toggle {
  width: 60px;
  height: 60px;
  padding: 7px;
  z-index: 40;
  border: solid 3px #495e89;
  border-radius: 25px;
  @media screen and (max-width: $small) {
    width: 45px;
    height: 45px;
    border-radius: 20px;
  }
}

.light-toggle {
  width: 50px;
  height: 50px;
  padding: 12px;
  z-index: 40;
  border: solid 3px #97aac6;
  border-radius: 25px;
  @media screen and (max-width: $small) {
    width: 35px;
    height: 35px;
    border-radius: 20px;
  }
}

.resume-dark-container {
  width: 80px;
  height: 80px;
  position: absolute;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  right: 25px;
  top: 115px;
  z-index: 60;
  cursor: pointer;
  @media screen and (max-width: $small) {
    top: 100px;
  }
}

.resume-dark-toggle {
  width: 50px;
  height: 50px;
  padding: 12px;
  z-index: 40;
  border: solid 3px #495e89;
  border-radius: 25px;
  @media screen and (max-width: $small) {
    width: 37px;
    height: 37px;
    padding: 10px;
    border-radius: 20px;
  }
}

.resume-icon {
  width: 50px;
  height: 50px;
  z-index: 40;
  @media screen and (max-width: $small) {
    width: 37px;
    height: 37px;
    border-radius: 20px;
  }
}

.resume-light-toggle {
  width: 50px;
  height: 50px;
  padding: 12px;
  z-index: 40;
  border: solid 3px #97aac6;
  border-radius: 25px;
  @media screen and (max-width: $small) {
    width: 37px;
    height: 37px;
    padding: 10px;
    border-radius: 20px;
  }
}

.expanding-background-container {
  height: 100dvh;
  width: 100dvw;
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.expanding-background {
  height: 100dvh;
  width: 100dvw;
  background: #495e89;
  position: absolute;
  z-index: 100;
  animation-name: content-page-animation;
  animation-duration: 7s;
}
