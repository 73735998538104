@import "../../index.css";

.navbar {
  top: 0;
  left: 0;
  position: absolute;
  padding: 64px 64px;
  background-color: "white";
  color: white;
  height: 50px;
  width: 50px;
  z-index: 50;
}

.nav-bar-container {
  top: 0;
  left: 0;
  margin-top: 55px;
  margin-left: 55px;
  height: 100vh;
  width: 100vw;
  position: fixed;
  transition: 0.5s;
  border-radius: 0px;
  background-color: white;
  box-shadow: 0 0 0 250vw white;
}

.nav-bar-container-closed {
  top: 0;
  left: 0;
  margin-top: 55px;
  margin-left: 55px;
  height: 65px;
  width: 65px;
  position: fixed;
  transition: 0.5s;
  border-radius: 100px;
  background-color: white;
  box-shadow: 0 0 0 0vw white;
}

.nav-bar-content {
  top: 50%;
  align-items: center;
  justify-content: center;
  visibility: visible;
  margin-top: -55px;
  margin-left: -55px;
  opacity: 1;
  transition: 0.3s;
  transition-delay: 0.1s;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  z-index: 50;
}
.nav-bar-content-closed {
  visibility: hidden;
  opacity: 0;
  transition: 0.3s;
  @extend .nav-bar-content;
}

.nav-options-button {
  text-decoration: none;
  text-align: center;
  background-color: transparent;
  color: #495e89;
  font-size: 25px;
  font-weight: 300;
  font-family: "Lato", sans-serif;
  border: 0px;
  padding: 30px;
  z-index: 50;
}
.nav-options-button:hover {
  color: #97aac6;
  cursor: pointer;
}
